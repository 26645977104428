@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&family=Roboto:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700;900&display=swap");

.business {
  /* padding-top: 40px; */
  top: 19px;
  overflow: hidden;
}

.business .pt-5 {
  padding-top: 0px !important;
}

.business {
  position: relative;
  top: -6px;
  background-color: #dce8eb;
  /* padding-top: 100px; */
  overflow: hidden;
  margin: 0px;
  /* width: 100vh; */
  /* height: 100vh; */
  z-index: 1;
}

.business .container {
  align-self: center;
  padding-top: 60px !important;
}

#tsparticles {
  position: absolute;
  z-index: -2;
  height: 100% !important;
  width: 100%;
}

#tsparticles .tsparticles-canvas-el {
  height: 100% !important;
}

canvas.tsparticles-canvas-el {
  height: 1000px !important;
  /* position: absolute !important; */
  /* z-index: -1000000000000; */
  /* left: 0;
  right: 0;
  margin: 0%;
  padding: 0%; */
  /* margin-top: 990px; */
}

.bg-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
  width: 100%;
  height: 100%;
}

.modal-form {
  position: relative;
  z-index: 3;
  width: 100%;
  top: 50px;
  justify-content: center;
  display: flex;
}

.modal-form-inner {
  /* position: absolute; */
  position: fixed;
}

.fixed-top {
  z-index: 1 !important;
}

#trust-kodex::before {
  content: "";
  height: 80px;
  margin-top: -80px;
  display: block;
  visibility: hidden;
}

#about::before {
  content: "";
  height: 80px;
  margin-top: -80px;
  display: block;
  visibility: hidden;
}

#services::before {
  content: "";
  height: 80px;
  margin-top: -80px;
  display: block;
  visibility: hidden;
}

#portfolio::before {
  content: "";
  height: 80px;
  margin-top: -80px;
  display: block;
  visibility: hidden;
}

#contact::before {
  content: "";
  height: 80px;
  margin-top: -80px;
  display: block;
  visibility: hidden;
}

/* ------------------------- Font ---------------------- */

.font-Poppins {
  font-family: Poppins;
}
h2 {
  font-size: 44px;
  color: #03a0ff;
}
h5 {
  font-size: 32px;
  color: #03a0ff;
}
h4 {
  font-size: 32px;
  color: #03a0ff;
}
p {
  font-size: 18px;
}
li {
  font-size: 18px;
}

/* Protofolio */
.nav-tabs .nav-link {
  width: fit-content;
}

#portfolio img {
  max-width: 100%;
  display: block;
  height: 500px;
  object-fit: cover;
}

#portfolio .masonryContainer {
  column-count: 4;
  column-gap: 10px;
}

/* .nav-link{
  color: #0077b5;
} */

#portfolio .nav-link:hover,
#portfolio .nav-link:active,
#portfolio .nav-link:visited,
#portfolio .nav-link:focus {
  background: #03a0ff;
  color: white !important;
  width: fit-content;
}

/* ------------ overlay ----------------- */

.overContainer {
  position: relative;
  width: 100%;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #52a1ff;
}
.overContainer:hover .overlay {
  opacity: 0.9;
}
.text {
  color: white;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  /* font-family: "popins"; */
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 100%;
  padding: 10px;
}

/* ------------------------- Carousel ---------------------- */
.menu-icon-style {
  position: absolute;
  z-index: 999;
}
.style-carousel {
  overflow: hidden;
}
.background-video {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  padding: none;
  /* height: auto; */
}
.background-video-s {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  padding: none;
  /* height: auto; */
}
.upper-sec {
  position: absolute;
  margin-top: 80px;
}
.carousel-img-overlay {
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.5s ease;
  opacity: 0.4;
}
.carousel-caption {
  width: 100%;
  vertical-align: middle;
  left: 0;
  bottom: 25.75rem;
}
.m-c-form {
  margin-right: 80px !important;
  margin-left: 80px !important;
}
.css-1b25eem-MuiFormControl-root-MuiTextField-root {
  margin: 0px !important;
}
.css-1w4vsez-MuiFormControl-root {
  margin: 0px !important;
}

.car-box-shadow {
  box-shadow: 0 8px 16px 0 rgba(3, 158, 255, 0.443);
}

@media only screen and (max-width: 1199px) {
  .background-video {
    height: auto;
    width: auto;
  }
  .background-video-s {
    height: auto;
    width: auto;
  }
  .m-c-form {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .upper-sec {
    position: absolute;
    margin-top: 160px;
  }
}

@media screen and (max-width: 375px) {
  .mob-font-size {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 567px) {
  .mob-font-size {
    font-size: 38px;
  }

  /* .nav-height {
    height: 100px !important;
  } */
  .bg-white-mb {
    background-color: white !important;
    position: absolute;
    top: 0;
    /* bottom: 0; */
  }
}

@media only screen and (max-width: 991px) {
  #tsparticles {
    position: absolute;
    z-index: -2;
    height: 100% !important;
    width: 100%;
  }
}

@media only screen and (max-width: 990px) {
  .carousel-caption {
    width: 100%;
    vertical-align: middle;
    left: 0;
    bottom: 5.75rem;
  }
  .btn-100 {
    width: 50% !important;
  }
  .business .pt-5 {
    padding-top: 180px !important;
  }

  .business .container {
    /* height: 100%; */
    padding-top: 100px !important;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .mob-font-size {
    font-size: 28px;
  }
  .md-mbl-font {
    font-size: 12px !important;
  }
  .nav-height {
    height: 100px !important;
  }
  .display-none {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .background-video {
    height: auto;
    left: -360px;
    width: auto;
  }
  .background-video-s {
    height: auto;
    width: auto;
  }

  /* .mob-font-size {
 
  } */
  .mob-dis-none {
    display: none;
  }
  .pl-0 {
    padding-left: 0rem !important;
    padding-right: 1rem !important;
  }
}

/* ------------------------- Contact Us ---------------------- */

.social-icon-facebook {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #03a0ff;
}
.social-icon-facebook:hover {
  color: #ffffff;
  background-color: #4267b2;
  border-radius: 100%;
  padding: 3px;
  transition: padding 0.6s;
}

.social-icon-twitter {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #03a0ff;
}

.social-icon-twitter:hover {
  color: #ffffff;
  background-color: #1da1f2;
  border-radius: 100%;
  padding: 4px;
  transition: padding 0.6s;
}

.social-icon-linkedin {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #03a0ff;
}

.social-icon-linkedin:hover {
  color: #ffffff;
  background-color: #0077b5;
  border-radius: 100%;
  padding: 4px;
  transition: padding 0.6s ease;
}

.social-icon-instagram {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #03a0ff;
}

.social-icon-instagram:hover {
  color: #ffffff;
  background-image: linear-gradient(
    to top right,
    #f58529,
    #feda77,
    #dd2a7b,
    #8134af,
    #515bd4
  );
  border-radius: 100%;
  padding: 4px;
  transition: padding 0.6s ease;
}

.css-1heko1s::after {
  border-bottom: 2px solid #fc6b03 !important;
}

.bg-map-vector {
  background-image: url("../assets/contact_us/map-vector-1.webp");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

/* ------------------------- Our Services ---------------------- */

.card-hov:hover {
  box-shadow: 0 8px 16px 0 rgba(3, 158, 255, 0.443);
}
/* ------------------------- Portfolio ---------------------- */
.carousel-control-prev-icon {
  background-color: #03a0ff !important;
  padding: 20px !important;
}
.carousel-control-next-icon {
  background-color: #03a0ff !important;
  padding: 20px !important;
}

/* ------------------------- About ---------------------- */

.bg-none {
  background-color: transparent !important;
}
.bg-lightgrey {
  background-color: lightgrey;
}
.color-orange-icon {
  color: #03a0ff;
}
.nav-link:hover {
  color: #03a0ff !important;
}
.ft-nav-link {
  color: #03a0ff;
}
.ft-nav-link:hover {
  color: black;
}

.btn-orange {
  background-color: #03a0ff;
  color: #ffffff;
  border-radius: 0px !important;
}
.btn-orange:hover {
  border-color: #03a0ff;
  color: #03a0ff;
  border-radius: 0px !important;
}
.btn-white {
  background-color: #ffffff;
  color: #03a0ff;
}
.btn-white:hover {
  border-color: #ffffff;
  color: #ffffff;
}

.card-body-orange {
  background-color: #03a0ff;
  color: #ffffff;
  border: none;
}
#addons .card:hover {
  transform: scale(1.07);
  transition: transform 0.6s ease-in-out;
}

.form-control:focus {
  border-color: #03a0ff;
  box-shadow: 0 8px 16px 0 rgba(3, 158, 255, 0.443);
}

/* ----------------------- Media Queries --------------------------- */

.z-index {
  /* background-color: #dd2a7b; */
}
.modal-content {
  background-color: transparent;
  border: none;
}
