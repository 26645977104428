.close-icon {
  font-size: 35px;
  cursor: pointer;
  color: #03a0ff;
  /* text-align: right; */
}
.close-icon:hover {
  font-size: 38px;
  cursor: pointer;
  /* text-align: right; */
}
.icon-div {
  margin-top: 10px;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
}
.thankyou-wrapper {
  width: 100%;
  height: 100vh;
  margin: auto;
  background: #ffffff;
  padding: 10px 0px 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.thankyou-wrapper h1 {
  font: 100px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #333333;
  padding: 0px 10px 10px;
}
.thankyou-wrapper p {
  font: 26px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #333333;
  padding: 5px 10px 10px;
}
.thankyou-wrapper h6 {
  cursor: pointer;
  font: 15px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #ffffff;
  display: block;
  text-decoration: none;
  width: 145px;
  background: #03a0ff;
  margin: 10px auto 0px;
  padding: 15px 20px 15px;
  border-bottom: 5px solid #03a0ff;
  /* text-transform: uppercase; */
}
.thankyou-wrapper h6:hover {
  font: 17px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #ffffff;
  display: block;
  text-decoration: none;
  width: 150px;
  background: #03a0ff;
  margin: 10px auto 0px;
  padding: 15px 20px 15px;
  border-bottom: 5px solid #03a0ff;
}
